<template>
  <c-box w="full">
    <c-flex flex-direction="column">
      <h1
        v-chakra
        font-weight="bold"
        font-size="18px"
        color="#111111"
        mt="24px"
      >
        Data Diet
      </h1>
      <c-flex
        flex-direction="column"
        mt="24px"
        w="full"
      >
        <data-diet-menu
          v-if="kuisioners"
          title="History Formulir Gizi"
          :items="kuisioners"
          @click="
            $router.push({
              name: 'client.profile.detail-quizionary',
              params: { programId: $event.programId, isEditable: $event.isEditable },
              meta: { isEditable: $event.isEditable },
            })
          "
        />
        <data-diet-menu
          v-if="historyMealPlan.length > 0"
          v-chakra
          title="History Panduan Makan"
          :items="historyMealPlan"
          mt="42px"
          @click="$router.push({
            name: 'meal-plan.with-id',
            params: { clientId: clientId },
            query: { programId: $event.programId },
          })"
        />
        <data-diet-menu
          v-if="historyProgressTracker.length > 0"
          v-chakra
          title="History Progress &amp; Tracker"
          :items="historyProgressTracker"
          mt="42px"
          @click="$router.push({
            name: 'client.progress-tracker',
            query: { programId: $event.programId, progressId: $event.id },
          })"
        />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import groupBy from 'lodash/groupBy'
import { mapState } from 'vuex'
import DataDietMenu from './data-diet-menu.vue'

export default {
  name: 'ProfileDataDietPage',
  components: {
    DataDietMenu,
  },
  data() {
    return {
      kuisioners: [],
      historyProgressTracker: [],
      historyMealPlan: [],
    }
  },
  computed: {
    ...mapState({
      mealPlan: (s) => s.clients.rencanaMakan,
    }),
    kuisioners_() {
      return this.kuisioners.map((it, index) => {
        let checkedKeys = [
          'generalData',
          'anthropometryData',
          'physicalActivityAbilities',
          'medicalHistory',
          'dietAndEatingHistory',
          'dailyActivities',
        ]
        let isEditable = checkedKeys
          .map((key) =>
            Object.values(it[key]).every((it) => it == null || it === ''),
          )
          .every((it) => it)

        return {
          ...it,
          name: `Formulir Gizi ${index + 1}`,
          isEditable,
        }
      })
    },
    mealPlan_() {
      let data = groupBy(this.mealPlan ?? [], 'programId')
      return Object.values(data).map((it) => {
        return {
          programId: it[0].programId,
          values: it,
        }
      })
    },
  },
  async mounted() {
    let programId = await this.$store
      .dispatch('mealPlan/getLatestProgramForClient')
      .then((it) => it.id)
    await this.$store.dispatch('clients/getRencanaMakan', programId)
    this.axios
      .get('/v1/clients/quizioner')
      .then((r) => {
        this.kuisioners = r.data.data.map((it, index) => ({
          ...it, name: `Formulir Gizi ${index + 1}`,
        }),
        )
      })

    this.axios.get('/v1/clients/progress-tracker/history')
      .then(r => r.data?.data)
      .then(r => {
        this.historyProgressTracker = r.map((it, idx) => ({
          ...it,
          name: `Progress & Tracker ${idx + 1}`,
        }))
      })
    this.axios.get('/v1/clients/meal-plans')
      .then(r => r.data?.data)
      .then(r => {
        this.historyMealPlan = r
      })
  },
}
</script>
